/**
 * "小V积分88枚",
            "小V积分111枚",
            "小V积分510枚",
            "现金红包0.5元",
            "现金红包1.1元",
            "现金红包5.1元",
            "劳动节大奖51元",
            "红包卡3.1元",
            "奖励卡0.2/10次",
            "惊喜电影票2张"

 */
export const prizeList = [
    {
        icon: require("@/assets/img/rotayTable/kuang.png"), // 奖品图片
        name: "小V积分", // 奖品名称
        title: '88枚',
        tname: '小V积分88枚',
        isPrize: 0 // 该奖项是否为奖品
    },
    {
        icon: require("@/assets/img/rotayTable/kuang.png"),
        name: "小V积分", // 奖品名称
        title: '111枚',
        tname: '小V积分111枚',
        isPrize: 1
    },
    {
        icon: require("@/assets/img/rotayTable/kuang.png"),
        name: "小V积分",
        title: '510枚',
        tname: '小V积分510枚',
        isPrize: 2
    },
    {
        icon: require("@/assets/img/rotayTable/kuang.png"),
        name: "现金红包",
        title: '0.5元',
        tname: '现金红包0.5元',
        isPrize: 3
    },
    {
        icon: require("@/assets/img/rotayTable/kuang.png"),
        name: "现金红包",
        title: '1.1元',
        tname: '现金红包1.1元',
        isPrize: 4
    },
    {
        icon: require("@/assets/img/rotayTable/kuang.png"),
        name: "现金红包",
        title: '5.1元',
        tname: '现金红包5.1元',
        isPrize: 5
    },
    {
        icon: require("@/assets/img/rotayTable/kuang.png"),
        name: "劳动节大奖",
        title: '51元',
        tname: '劳动节大奖51元',
        isPrize: 6
    },
    {
        icon: require("@/assets/img/rotayTable/kuang.png"),
        name: "红包卡",
        title: '3.1元',
        tname: '红包卡3.1元',
        isPrize: 7
    }, {
        icon: require("@/assets/img/rotayTable/kuang.png"),
        name: "奖励卡",
        title: '0.2/10次',
        tname: '奖励卡0.2/10次',
        isPrize: 8
    }
    , {
        icon: require("@/assets/img/rotayTable/kuang.png"),
        name: "惊喜电影票",
        title: '2张',
        tname: '惊喜电影票2张',
        isPrize: 9
    }
]


