<template>
  <div class="container">
    <div class="lucky-wheel">
      <div class="right_img">
        <img class="img_right" src="@/assets/img/rotayTable/4.png" />
      </div>
      <div class="wheel-main">
        <div v-if="drawInfo> 0" class="wheel-start" @click="beginRotate()"></div>
        <div v-if="drawInfo==0" class="wheel_hui" @click="beginhuiRotate()"></div>
        <div class="wheel-bg" :style="rotateStyle">
          <div class="prize-list"></div>
        </div>
      </div>
      <div class="left_img">
        <img class="img_left" src="@/assets/img/rotayTable/3.png" />
      </div>
    </div>

    <div style="position: absolute; bottom: 0">
      <div class="mast" v-if="isShow" @touchmove.prevent></div>
      <div class="mast_all" v-if="isShow" @touchmove.prevent>
        <div class="mast_content" style="display: flex; justify-content: center">
          <img :src="prize.icon" class="toast-picture" />
          <div class="spanjiang">中奖啦</div>
          <div class="span">{{ prize.tname }}</div>
        </div>
        <div @click="closeToast" class="btn">
          <Button class="toast-cancel">收下</Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { prizeList } from "./config";
import { getDraw } from "@/api/components/glorious";
const CIRCLE_ANGLE = 360;
const config = {
  // 总旋转时间
  duration: 2000,
  // 旋转圈数
  circle: 3,
  mode: "ease-in-out",
};

export default {
  props: ["drawInfo"],
  data() {
    return {
      duration: 3000, // 转盘旋转时间
      prizeList: [], // 奖品列表
      rotateAngle: 0, // 旋转角度
      prize: "",
      isShow: false,
      config: {},
      draw: {},
      isStar: false,
    };
  },
  created() {
    // 初始化一些值
    this.angleList = [];
    // 是否正在旋转
    this.isRotating = false;
    // 基本配置
    this.config = config;

    // 获取奖品列表
    this.initPrizeList();
  },
  computed: {
    rotateStyle() {
      return `
        -webkit-transition: transform ${this.config.duration}ms ${this.config.mode};
        transition: transform ${this.config.duration}ms ${this.config.mode};
        -webkit-transform: rotate(${this.rotateAngle}deg);
            transform: rotate(${this.rotateAngle}deg);`;
    },
  },
  methods: {
    beginhuiRotate() {
      this.$toast({
        message: "需要更多徽章来获得转盘次数！",
      });
    },
    initPrizeList() {
      this.prizeList = this.formatPrizeList(prizeList);
    },
    // 格式化奖品列表，计算每个奖品的位置
    formatPrizeList(list) {
      // 记录每个奖的位置
      const angleList = [];
      const l = list.length;
      // 计算单个奖项所占的角度
      const average = CIRCLE_ANGLE / l;
      const half = average;
      // 循环计算给每个奖项添加style属性
      list.forEach((item, i) => {
        console.log("-average", average, half, item, i);
        // 每个奖项旋转的位置为 当前 i * 平均值 + 平均值 / 2
        //const angle = -(i * average);
        const angle = i * average + average / 2;
        // 增加 style
        item.style = `-webkit-transform: rotate(${angle}deg);
                      transform: rotate(${angle}deg);`;
        // 记录每个奖项的角度范围
        angleList.push(i * average + half);
      });
      this.angleList = angleList;
      return list;
    },
    beginRotate() {
      this.isStar = true;
      if (this.isRotating) {
      } else {
        // 随机获取下标
        this.index = this.random(this.prizeList.length - 1);
        // 减少剩余抽奖次数
        // 开始旋转
        this.rotating();
      }
    },
    random(max, min = 0) {
      return parseInt(Math.random() * (max - min + 1) + min);
    },
    rotating() {
      const { isRotating, angleList, config, rotateAngle, index } = this;
      this.isRotating = true;
      this.isStar = true;
      // 计算角度
      const angle =
        // 初始角度
        rotateAngle +
        // 多旋转的圈数
        config.circle * CIRCLE_ANGLE +
        // 奖项的角度
        angleList[index] -
        (rotateAngle % CIRCLE_ANGLE);
      this.rotateAngle = angle;
      setTimeout(() => {
        getDraw().then((res) => {
          let { code, data } = res.data;
          this.draw = data;
          if (code == 10002) {
            this.rotateOver();
          }
        });
      }, 1300);
      // 旋转结束后，允许再次触发
    },
    rotateOver() {
      this.isRotating = false;
      this.prize = prizeList[this.draw.type];
      this.isShow = true;
      // this.isStar = false;
    },
    //关闭弹窗
    closeToast() {
      this.prize = null;
      this.isShow = false;
      this.$emit("handleInfo");
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
}
.lucky-wheel {
  width: 100%;
  background-size: 100%;
  padding-top: 60px;
  position: relative;
}
.right_img {
  width: 176px;
  right: 0;
  position: absolute;
  top: -20px;
}
.img_right {
  width: 176px;
}

.wheel-main {
  margin: 15px auto;
  position: relative;
  width: 339px;
  height: 339px;
}
.pan {
  width: 300px;
  position: absolute;
  z-index: 100;
  left: 50%;
  margin-left: -150px;
  top: 21px;
}
.wheel-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 339px;
  height: 100%;
  background: url("~@/assets/img/rotayTable/5.png") no-repeat center top;
  background-size: 100%;
  color: #fff;
}
div,a,img {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select:none;
}
.wheel-start {
  position: absolute;

  top: 50%;
  left: 50%;
  z-index: 200;
  width: 106px;
  height: 123px;
  background: url("~@/assets/img/rotayTable/start.png") no-repeat center top;
  background-size: 100%;
  transform: translate3d(-50%, -50%, 0);
}
.wheel_hui {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 200;
  width: 106px;
  height: 123px;
  background: url("~@/assets/img/rotayTable/hui.png") no-repeat center top;
  background-size: 100%;
  transform: translate3d(-50%, -50%, 0);
}
.wheel-bg div {
  text-align: center;
}
.prize-list {
  width: 100%;
  height: 100%;
  position: relative;
}
.prize-list .prize-item {
  position: absolute;
  width: 90px;
  height: 150px;
  top: 0;
  left: 50%;
  margin-left: -47.5px;
  transform-origin: 50% 100%;
}

.prize-pic img {
  width: 4.0625rem;
  height: 2.5rem;
  margin-top: 1.5625rem;
}
.pad40 {
  padding-top: 40px;
}
.prize-count {
  font-size: 0.875rem;
}
.prize-type {
  font-size: 13px;
  color: #551d10;
  padding-top: 20px;
}
.main {
  position: relative;
  width: 100%;
  min-height: 14.25rem;
  background: rgb(243, 109, 86);
  padding-bottom: 1.6875rem;
}

.bg-p {
  width: 100%;
  height: 2.95rem;
  background: rgb(252, 207, 133);
}
.content {
  position: absolute;
  top: 0.175rem;
  left: 0;
  background: rgb(243, 109, 86);
  width: 100%;
  height: 5.1875rem;
  font-size: 1.125rem;
  color: #ffeb39;
  padding-left: 6.75rem;
}
.content div {
  text-align: left;
}
.tip {
  position: relative;
  margin: 2.5rem auto 0;
  width: 17.5rem;
  border: 1px solid #fbc27f;
}
.tip-title {
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1rem;
  color: #fccc6e;
  background: rgb(243, 109, 86);
  padding: 0.3125rem 0.625rem;
}
.tip-content {
  padding: 1.5625rem 0.625rem;
  font-size: 0.875rem;
  color: #fff8c5;
  line-height: 1.5;
}
.toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  filter: 100px;
  z-index: 10000;
  opacity: 1;
  width: 100%;
  height: 100%;
}
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20000;
  transform: translate(-50%, -50%);
  width: 100%;
  margin-left: -165px;
}
.toast-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px dotted #fccc6e;
}
.toast-picture {
  width: 329px;
  height: 432px;
}
.toast-pictrue-change {
  position: absolute;
  top: -1.5rem;
  left: -1.375rem;
  width: 17.5rem;
  height: 3.125rem;
}
.toast-title {
  padding: 2.8125rem 0;
  font-size: 18px;
  color: #fc7939;
  text-align: center;
}
.toast-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.9375rem;
}
.toast-btn div {
  background-image: -moz-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );

  background-image: -ms-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );
  background-image: -webkit-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );
  box-shadow: 0px 4px 0px 0px rgba(174, 34, 5, 0.7);
  width: 4.6875rem;
  height: 1.875rem;
  border-radius: 1.875rem;
  text-align: center;
  line-height: 1.875rem;
  color: #fff;
}

.span {
  position: absolute;
  padding-top: 30px;
  color: #fff;
  width: 100%;
  top: 57%;
  font-size: 16px;
  font-weight: bold;
}
.left_img {
  width: 88px;
  position: absolute;
  left: 0;
  bottom: -16px;
  z-index: 1000;
}
.img_left {
  width: 88px;
}
.spanjiang {
  position: absolute;
  padding-top: -0.2rem;
  color: #ff8856;
  font-size: 28px;
  top: 45%;
  width: 100%;
  font-weight: bold;
}
.mast {
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.7;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}
.mast_all {
  width: 100vw;
  position: fixed;
  top: 20%;
  z-index: 1001;
}
.toast-cancel {
  background: #ff8856;
  border: none;
  width: 160px;
  padding: 15px;
  border-radius: 30px;
  font-size: 18px;
  color: #fff;
}
.btn {
  margin-top: -57px;
}
</style>
